import React from "react"
import EpisodePlayer from "../components/EpisodePlayer"
import { Column, Container, Row } from "../components/Grid"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import Resource from "../components/Resource"
import Section from "../components/Section"
import SEO from "../components/Seo"

const PharmacyCareersPage = ({ location }) => {
  const queryParameters = new URLSearchParams(location.search)
  const keyIndex = queryParameters.get("keyIndex")
  let [key, index] = keyIndex ? keyIndex.split("_") : ""
  index = Number.parseInt(index)
  return (
    <Layout className="pharmacy-careers">
      <SEO title="Pharmacy Careers" />
      {/***************  HERO ***************/}
      <Section className="hero hero--pharmacy-careers">
        <Hero
          title="Career Exploration"
          infographic="img-hero-pharmacy-careers.png"
          body="What is the role of a pharmacist?  Discover more about earning your PharmD degree to become a pharmacist and how they support the wellness of the communities they serve."
          image="img-hero-pharm-careers.png"
        />
      </Section>

      <Section className="midgray-background">
        <Container fullWidth>
          <Row>
            <h2 style={{ color: "#0B315E", fontSize: "32px" }}>
              Pharmacist Career Profile Videos
            </h2>
            <p>
              Career opportunities for pharmacists are endless, offering a
              chance to connect with a deep sense of purpose by serving your
              community. It's a rewarding role that comes with significant
              responsibility, as you contribute to the health and well-being of
              those around you.
            </p>
            <EpisodePlayer
              style={{ padding: "0 1rem 2rem" }}
              playerLabelTitle=""
              presetVideo={key === "career-profiles"}
              presetVideoIndex={index}
              episodes={[
                {
                  title: "Maria Gil-Flores",
                  videoDuration: "3:43 min",
                  guidSrc: "22f0ce83-1ebc-40ee-9897-64dbd98e029f",
                  personDescription:
                    "Fourth-generation pharmacist, Maria Gil-Flores, talks about her role as a pharmacist and pharmacy leader.",
                  listImage: "pharmacy-careers-maria-thumbnail.png",
                  listDescription:
                    "Fourth-generation pharmacist, Maria Gil-Flores, talks about her role as a pharmacist and pharmacy leader.",
                },
                {
                  title: "Jaimin Patel",
                  videoDuration: "2:07 min",
                  personDescription:
                    "Jaimin details his journey through various pharmacist career pathways, all experienced because of his PharmD degree.",
                  guidSrc: "f5cb8c31-41df-4741-b097-19504a9921ed",
                  listImage: "pharmacy-careers-jaimin-patel-thumbnail.png",
                  listDescription:
                    "Jaimin details his journey through various pharmacist career pathways, all experienced because of his PharmD degree.",
                },
              ]}
            />
          </Row>
        </Container>
      </Section>

      <Section className="midgray-background">
        <Container>
          <Row>
            <Column span={12}>
              <h2 style={{ color: "#0B315E", fontSize: "32px" }}>
                Career Profiles
              </h2>
              <Resource
                resourceContainerClass="pl-0 pr-0"
                halfLabel
                label="PHARMACY TECHNICIAN"
                image="CVS-Pharma-CareerProfile-PharmacyTechnician.jpg"
                title="Teaming up with Pharmacists"
                description={
                  <p className="mt-2 mb-2">
                    A pharmacy technician is at the frontline of community
                    pharmacy, supporting fulfillment of prescriptions, inventory
                    activities, assisting the pharmacist to answer questions,
                    and so much more.
                  </p>
                }
                actions={[
                  {
                    label: "Career Profile",
                    path:
                      "/docs/CVS-Pharma-CareerProfile-PharmacyTechnician.pdf",
                    type: "download",
                    locked: false,
                  },
                ]}
                horizontal
              />
              <Resource
                resourceContainerClass="pl-0 pr-0"
                halfLabel
                label="Community Pharmacist"
                image="CVS-Pharma-CareerProfile-Pharmacist.jpg"
                title="Serving and Impacting the Community"
                description={
                  <p className="mt-2 mb-2">
                    Community pharmacists are your most accessible healthcare
                    experts, here to support community health through
                    prescription dispensing, providing health services, patient
                    education, patient advocacy, and so much more.
                  </p>
                }
                actions={[
                  {
                    label: "Career Profile",
                    path:
                      "/docs/CVS-Pharma-CareerProfile-CommunityPharmacist.pdf",
                    type: "download",
                    locked: false,
                  },
                ]}
                horizontal
              />
              <Resource
                resourceContainerClass="pl-0 pr-0"
                halfLabel
                label="Business Leader"
                image="CVS-Pharma-CareerProfile-PharmacyBusinessLeader.jpg"
                title="Building Healthcare Excellence at a Broader Scale"
                description={
                  <p className="mt-2 mb-2">
                    Business leaders amplify community impact by driving success
                    across multiple pharmacies in a market including educating
                    and elevating the pharmacy teams to provide excellent
                    patient care while managing business operations.
                  </p>
                }
                actions={[
                  {
                    label: "Career Profile",
                    path:
                      "/docs/CVS-Pharma-CareerProfile-PharmacyBusinessLeader.pdf",
                    type: "download",
                    locked: false,
                  },
                ]}
                horizontal
              />
              <Resource
                resourceContainerClass="pl-0 pr-0"
                halfLabel
                label="Pharmacy Operations Manager"
                image="CVS-Pharma-CareerProfile-PharmacyOperationsManager.jpg"
                title="Addressing Health from All Angles"
                description={
                  <p className="mt-2 mb-2">
                    A Pharmacy Operations Manager is at the center of it all,
                    using pharmacist expertise to contribute to and impact the
                    overall performance and success of an entire chain of
                    pharmacies.
                  </p>
                }
                actions={[
                  {
                    label: "Career Profile",
                    path:
                      "/docs/CVS-Pharma-CareerProfile-PharmacyOperationsManager.pdf",
                    type: "download",
                    locked: false,
                  },
                ]}
                horizontal
              />
              <Resource
                resourceContainerClass="pl-0 pr-0"
                halfLabel
                label="Specialized Pathways"
                image="CVS-Pharma-CareerProfile-SpecializedPharmacyCareerPathways.jpg"
                title="Focusing Your Career"
                description={
                  <p className="mt-2 mb-2">
                    Whether you’re interested in providing in-home or long-term
                    care or maximizing the capabilities of mail service pharmacy
                    services, there are limitless pathways for pharmacists to
                    explore.
                  </p>
                }
                actions={[
                  {
                    label: "Career Profile",
                    path:
                      "/docs/CVS-Pharma-CareerProfile-SpecializedPharmacyCareerPathways.pdf",
                    type: "download",
                    locked: false,
                  },
                ]}
                horizontal
              />
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}
export default PharmacyCareersPage
